<template>
  <v-container class="general">
    <PageTitle :title="'Users'" />
    <template v-if="!modelReady">
      <v-progress-linear
        :indeterminate="true"
        class="theme-accent"
      ></v-progress-linear>
    </template>
    <div v-if="modelReady">
      <v-layout wrap raw>
        <v-flex xs6 pr-2>
          <v-text-field
            type="text"
            v-model="model.first_name"
            :error-messages="errors.collect('first_name')"
            v-validate.disable="'required|min:2'"
            data-vv-name="first_name"
            :data-vv-as="$tc('first-name', 2)"
            :label="$tc('first-name', 1)"
          ></v-text-field>
        </v-flex>
        <v-flex xs6 pl-2>
          <v-text-field
            type="text"
            v-model="model.last_name"
            :error-messages="errors.collect('last_name')"
            v-validate.disable="'required|min:2'"
            data-vv-name="last_name"
            :data-vv-as="$tc('last-name', 2)"
            :label="$tc('last-name', 1)"
          ></v-text-field>
        </v-flex>
        <v-flex xs12>
          <v-text-field
            type="email"
            v-model="model.email"
            :error-messages="errors.collect('email')"
            v-validate.disable="'required|email'"
            data-vv-name="email"
            :data-vv-as="$tc('email', 2)"
            :label="$tc('email', 1)"
          ></v-text-field>
        </v-flex>
        <v-flex xs4 pr-2>
          <v-combobox
            v-model="model.phone_code"
            :items="dialCodes"
            v-validate.disable="model.phone ? 'required' : ''"
            :error-messages="errors.collect('phone_code')"
            :data-vv-name="'phone_code'"
            :data-vv-as="$tc('phone-code', 2)"
            :search-input.sync="searchInput"
            :label="$tc('phone-code', 1)"
            persistent-hint
            maxlength="3"
          >
            <template slot="selection" slot-scope="data">
              <div :style="{ fontSize: '14px' }">
                <span
                  v-if="iso2(data.item)"
                  :class="`mr-2 flag-icon flag-icon-${iso2(data.item)}`"
                ></span>
                <span class="font-weight-medium">+{{ data.item }}</span>
                <span v-if="country(data.item)" class="ml-2">{{
                  country(data.item)
                }}</span>
              </div>
            </template>
            <template slot="item" slot-scope="data">
              <div :style="{ fontSize: '14px' }" class="slot-item">
                <span
                  v-if="iso2(data.item)"
                  :class="`mr-2 flag-icon flag-icon-${iso2(data.item)}`"
                ></span>
                <span class="font-weight-medium">+{{ data.item }}</span>
                <span v-if="country(data.item)" class="ml-2">{{
                  country(data.item)
                }}</span>
              </div>
            </template>
          </v-combobox>
        </v-flex>
        <v-flex xs8 pl-2>
          <v-text-field
            type="number"
            v-model="model.phone"
            :error-messages="errors.collect('phone')"
            v-validate.disable="phoneNumberRules"
            data-vv-name="phone"
            :data-vv-as="$tc('phone', 2)"
            :label="$tc('phone', 1)"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 pl-2>
          <v-text-field
            type="text"
            v-model="model.email_sender_domains"
            :error-messages="errors.collect('email_sender_domains')"
            v-validate.disable="'domain'"
            data-vv-name="email_sender_domains"
            :data-vv-as="$tc('email_sender_domains')"
            :label="$tc('email_sender_domains')"
          ></v-text-field>
        </v-flex>
        <v-flex xs12>
          <v-autocomplete
            v-model="model.roles"
            :items="roles"
            multiple
            item-text="description"
            item-value="id"
            :label="$tc('roles', 1)"
            return-object
            persistent-hint
            chips
          >
            <template slot="no-data">
              <div class="pa-3">No roles found</div>
            </template>
            <template slot="selection" slot-scope="data">
              <v-chip close @input="remove(data.item)">{{
                data.item.description
              }}</v-chip>
            </template>
            <template slot="item" slot-scope="data">
              <div class="slot-item">{{ data.item.description }}</div>
            </template>
          </v-autocomplete>
        </v-flex>
        <v-flex xs12>
          <v-text-field
            v-model="model.password"
            @click:append="show_password = !show_password"
            :type="show_password ? 'text' : 'password'"
            :append-icon="show_password ? 'visibility' : 'visibility_off'"
            :error-messages="errors.collect('password')"
            v-validate.disable="passwordRequired + 'min:6'"
            data-vv-name="password"
            :data-vv-as="$tc('password', 2)"
            :label="$tc('password', 1)"
            required
          ></v-text-field>
        </v-flex>
      </v-layout>
      <div class="pb-4 text-xs-center">
        <v-btn
          v-if="model.id"
          round
          class="red white--text text-none"
          @click="deleteUser"
          >{{ $t("delete") }}</v-btn
        >
        <v-btn round class="theme-primary text-none" @click="goBack">{{
          $t("cancel")
        }}</v-btn>
        <v-btn
          v-if="model.id"
          round
          class="primary text-none"
          @click="updateUser"
          >{{ $t("save") }}</v-btn
        >
        <v-btn
          v-if="!model.id"
          round
          class="primary text-none"
          @click="createUser"
          >{{ $t("create") }}</v-btn
        >
      </div>
    </div>
  </v-container>
</template>

<script>
import { countries } from "@/countries.js";
import "flag-icon-css/css/flag-icon.css";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    searchInput: null,
    model: {
      first_name: "",
      last_name: "",
      email: "",
      phone_code: null,
      phone: null,
      email_sender_domains: null,
    },
    modelReady: false,
    roles: [],
    show_password: false,
  }),
  computed: {
    phoneNumberRules() {
      return process.env.VUE_APP_PHONE_NUMBER_RULES || "numeric|min:8|max:12";
    },
    ...mapGetters(["currentUser"]),
    userId() {
      return this.$route.params.user_id;
    },
    dialCodes() {
      return [
        { header: this.$t("dialCodeHeader") },
        ...countries.map((el) => el.dialCode),
      ];
    },
    passwordRequired() {
      return this.model.id ? "" : "required|";
    },
  },
  mounted() {
    this.getUser();
    this.listRoles();
  },
  methods: {
    iso2(dialCode) {
      if (!dialCode) return;

      const country = countries.find((el) => el.dialCode === Number(dialCode));

      if (!country) return;

      return country.iso2;
    },
    country(dialCode) {
      if (!dialCode) return;

      const country = countries.find((el) => el.dialCode === Number(dialCode));

      if (!country) return;

      return country.name;
    },
    goBack() {
      this.$router.go(-1);
    },
    remove(item) {
      this.model.roles.splice(this.model.roles.indexOf(item), 1);
      this.model.roles = [...this.model.roles];
    },
    getUser() {
      this.modelReady = false;

      if (!this.userId) {
        this.modelReady = true;
        return;
      }

      const params = [this.userId, { with_attributes: 1 }];

      this.$api.users
        .get(...params)
        .then((response) => {
          this.modelReady = true;
          this.model = response.data.data;
        })
        .catch(() => {
          this.modelReady = true;
        });
    },
    transformForSave(model) {
      return {
        first_name: model.first_name,
        last_name: model.last_name,
        email: model.email,
        phone_code: model.phone ? model.phone_code : "",
        phone: model.phone,
        email_sender_domains: (model.email_sender_domains || "")
          .split(",")
          .map((domain) => domain.trim())
          .join(","),
        roles: model.roles ? model.roles.map((role) => role.id) : [],
        password: model.password,
      };
    },
    listRoles() {
      this.$api.roles.list().then(
        (response) => {
          this.roles = response.data.data;
        },
        () => {},
      );
    },
    async createUser() {
      const isValid = await this.$validator.validate();

      if (!isValid) return;

      const specs = this.transformForSave(this.model);

      this.$api.users
        .create(specs)
        .then((response) => {
          this.$store.dispatch("addNotification", {
            message: `User created`,
          });

          this.$router
            .push({
              name: "users",
            })
            .catch(() => {});
        })
        .catch((error) => this.errorMessageShow(error));
    },
    async updateUser() {
      const isValid = await this.$validator.validate();

      if (!isValid) return;

      const specs = [this.userId, this.transformForSave(this.model)];

      this.$api.users
        .update(...specs)
        .then((response) => {
          this.$store.dispatch("addNotification", {
            message: `User updated`,
          });
          this.updateCurrentUser();
          this.$router
            .push({
              name: "users",
            })
            .catch(() => {});
        })
        .catch((error) => this.errorMessageShow(error));
    },
    async deleteUser() {
      if (!this.userId) return;

      const isConfirmed = await this.$vuetifyConfirmDialog.open(
        "User deletion",
        "Are you sure you want delete " +
          this.model.first_name +
          " " +
          this.model.last_name +
          "?",
        "Cancel",
        "Confirm",
      );

      if (!isConfirmed) return;

      this.$api.users
        .delete(this.userId)
        .then((response) => {
          this.$store.dispatch("addNotification", {
            message: `User ${
              this.model.first_name + " " + this.model.last_name
            } deleted`,
          });

          this.$router
            .push({
              name: "users",
            })
            .catch(() => {});
        })
        .catch((error) => this.errorMessageShow(error));
    },
    updateCurrentUser() {
      if (this.userId == this.currentUser.id) {
        this.$api.auth.me({ with_attributes: 1 }).then((response) => {
          this.$store.commit("SET_USER", response.data.data);
        });
      }
    },
  },
};
</script>

<style scoped>
/* STYLING */

.model-gray-header {
  background: rgba(0, 0, 0, 0.02);
  padding: 30px 30px 15px 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.model-gray-header .avatars {
  width: 70px;
  display: inline-block;
}

.model-gray-header .flex:first-child .v-input {
  width: calc(100% - 85px);
  display: inline-block;
}

.model-gray-header .flex:last-child .v-input {
  margin-left: 15px;
}

.width50 {
  width: calc(50% - 15px);
}

.pad30 {
  padding: 30px;
  padding-top: 40px;
}
</style>
